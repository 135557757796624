import React from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import ShopItem from '../components/shopItem';
import Layout from "../components/layout"


const Shop = (props) => {
  const products = props.data.allShopifyProduct;
  console.log(products)
  return (
    <Layout>
      <div className="shop container">
        <div className="listing">
          {
            products.edges.map((item) => {
              return (
                <ShopItem
                  key={item.node.id}
                  name={item.node.title}
                  slug={item.node.handle}
                  fluid={item.node.images[0].localFile.childImageSharp.fluid}
                  price={item.node.priceRange.minVariantPrice.amount}
                />
              )
            })
          }
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query allProducts {
  allShopifyProduct {
    edges {
      node {
        id
        shopifyId
        title
        handle
        descriptionHtml
        productType
        vendor
        priceRange {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        images {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        variants {
          id
          title
          price
        }
      }
    }
  }
}`

const mapStateToProps = state => ({
  product: state._product,
});

const connectedShop = connect(mapStateToProps, {})(Shop);

export default connectedShop;
